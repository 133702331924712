// Utils
import { DivIcon } from "leaflet";

// Utils and Constants
import { toLocaleString } from "../common-utils/string-utils/StringUtils";
import { getMediaOts } from "./mavinMetrics";
import {
  LocalStorageKeys,
  OrganizationIdsMapping,
  PatrikaSellerId,
} from "../constants/GeneralConstants";
import { getItem } from "./localstorage";
import { checkIsOrgSpecific } from "./OrgUtils";

// Default values
const MIN_VALUE = 0;
const MAX_VALUE = Number.MAX_VALUE;

// TODO :: move this function to util file
function isWithinRange(value, minValue, maxValue) {
  const minimumValue = minValue || MIN_VALUE;
  const maximumValue = maxValue || MAX_VALUE;

  return value && value >= minimumValue && value <= maximumValue;
}

export function filterMedia(mediaList = [], filters = {}) {
  const {
    litStatus = [],
    orientation = [],
    mediaTypes = [],
    title,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
  } = filters;

  const minImpression = filters.impressions || MIN_VALUE;
  // checks conditions
  const conditions = {
    height: (media) => isWithinRange(media.height, minHeight, maxHeight),

    width: (media) => isWithinRange(media.width, minWidth, maxWidth),

    minImpression: (media) => {
      const { ots = 0 } = getMediaOts(media) || {};
      return ots >= minImpression;
    },

    type: (media) =>
      mediaTypes.length < 1 || mediaTypes.includes(media.mediaGroup),

    litStatusCheck: (media) =>
      litStatus.length < 1 || litStatus.includes(media.litStatus),

    title: (media) =>
      !title || media.title.toLowerCase().includes(title.toLowerCase()),

    orientation: (media) =>
      orientation.length < 1 || orientation.includes(media.orientation),
  };

  // filter method
  const filteredMediaList = mediaList.filter((media) =>
    Object.values(conditions).every((condition) => condition(media))
  );

  return filteredMediaList;
}

/**
 * sort media by ots and lts
 *
 * @param {*} mediaList ==> [{},{}]
 * @param {*} sortKey ==> "ots","lts","none"
 * @returns  sorted media list
 */

function getMediaLtsFn(media) {
  const { ltsSummary = {} } = media;
  return ltsSummary.lts;
}

export function sortMediaByImpression(mediaList = [], sortKey) {
  const mediaListToSort = [...mediaList];

  // if sort key is "none" return "mediaList"
  if (sortKey === "none") {
    return mediaList;
  }

  const impressions = sortKey === "ots" ? getMediaOts : getMediaLtsFn;
  return mediaListToSort.sort(
    (mediaOne, mediaTwo) => impressions(mediaTwo) - impressions(mediaOne)
  );
}

/**
 *
 * @param {*} mediaCountMap
 * @returns "totalMediaCount" and "totalCount of different-different mediaTypes"
 */
export function prepareMediaTypesCount(mediaCountMap) {
  // Calculating totalMediaCount
  const totalMediaCount = Object.values(mediaCountMap).reduce(
    (acc, eachType) => acc + eachType,
    0
  );

  const mediaTypesCount = {
    BILLBOARDS: {
      displayName: "Billboards",
      count: toLocaleString(mediaCountMap["BILLBOARDS"]) || 0,
    },
    GANTRIES: {
      displayName: "Gantries",
      count: toLocaleString(mediaCountMap["GANTRIES"]) || 0,
    },
    UNIPOLES: {
      displayName: "Unipoles",
      count: toLocaleString(mediaCountMap["UNIPOLES"]) || 0,
    },
    FOB: {
      displayName: "FOB",
      count: toLocaleString(mediaCountMap["FOB"]) || 0,
    },
    TRANSIT_STATIONS: {
      displayName: "BQS",
      count: toLocaleString(mediaCountMap["TRANSIT_STATIONS"]) || 0,
    },
    DOOH: {
      displayName: "DOOH",
      count: toLocaleString(mediaCountMap["DOOH"]) || 0,
    },
    TOTAL: toLocaleString(totalMediaCount) || 0,
  };

  return mediaTypesCount;
}

/**
 * Function which returns the DivIcon for the media..
 */
export function getMediaIcon(color) {
  const mediaMarkerHtmlStyle = `
  background-color: ${color}`;
  return new DivIcon({
    className: "icon-media",
    html: `<span style="${mediaMarkerHtmlStyle}" />`,
  });
}
